import '../../css/sign.css';
import Image from '../../photo/lg.png';
import GGL from '../../photo/ggl.png';
import FBB from '../../photo/fbb.jfif';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const SignUp = () => {
    const [input, setInput] = useState({});
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleInput = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (!input.email || !input.pass || !input.pass2) {
                setMessage("Email and both password fields are required");
                return;
            }
            // Password matching
            if (input.pass !== input.pass2) {
                setMessage("Passwords do not match");
                return;
            }
          
            const response = await axios.post("https://superb-cheesecake-62c90c.netlify.app/api/signup", {
                email: input.email,
                pass: input.pass
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 201) {
                setMessage(response.data.message);
                setTimeout(() => {
                    navigate('/signin');
                }, 2000); // Navigate after 2 seconds
            } else {
                setMessage(response.data.message);
            }
        } catch (err) {
            // Handle errors for status codes outside 2xx range
            if (err.response) {
                // The request was made and the server responded with a status code
                // that falls outside the range of 2xx
                setMessage(err.response.data.message);
            } else {
                // Something else happened while setting up the request
                console.error(err);
                setMessage("An unexpected error occurred.");
            }
        }
    }

    return (
        <div className='sign-body'>
            <div className='sign-page-signup'>
                <div className='logo'>
                    <img src={Image} alt='logo' />
                </div>
                <p className='msg'>{message}</p>
                <div className='auth-signup'>
                    <div className='u-name'>
                        <label>Email: </label>
                        <input
                            type='text'
                            placeholder='Enter email'
                            name='email'
                            value={input.email || ''}
                            onChange={handleInput}
                        />
                    </div>
                    <div className='pass'>
                        <label>Enter password: </label>
                        <input
                            type='password'
                            placeholder='Enter password'
                            name='pass'
                            value={input.pass || ''}
                            onChange={handleInput}
                        />
                    </div>
                    <div className='pass'>
                        <label>Re-enter password: </label>
                        <input
                            type='password'
                            placeholder='Re-enter password'
                            name='pass2'
                            value={input.pass2 || ''}
                            onChange={handleInput}
                        />
                    </div>
                    <div className='forgot-signup-signup'>
                        <a href='/signin'>Sign in</a>
                    </div>
                    <div className='login-btn'>
                        <button onClick={handleSubmit}>Sign Up</button>
                    </div>
                </div>

                <div className='other-m'>
                    <div id="login_google">
                        <p>Sign up with Google</p>
                        <img src={GGL} alt="Google" />
                    </div>
                    <div id="login_facebook">
                        <p>Sign up with Facebook</p>
                        <img src={FBB} alt="Facebook" />
                    </div>
                </div>
            </div>
        </div>
    );
};

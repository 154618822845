import React, { useEffect, useState } from 'react';
import "../../css/main.css";
import image from './image.png';

export const Body = () => {
    const str = 'Building stunning future AI';
    const [head, setHead] = useState('');
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index < str.length) {
            const interval = setInterval(() => {
                setHead(prev => prev + str[index]);
                setIndex(prevIndex => prevIndex + 1);
            }, 40);

            return () => clearInterval(interval); 
        }
    }, [index, str]);

    return (
        <div className="main-body">
            <div className="body">
                <div>
                    <h2 className="body-h2">{head}</h2>
                    <p className="body-p">WEB DEV . VIEW AI . AI DEV</p>
                </div>
                <div className="image">
                    <img className="image" src={image} alt="AI representation" />
                </div>
            </div>
            <div className="learn-more">
                <button>learn more</button>
            </div>
        </div>
    );
};

import '../../css/main.css'
import logo from '../../photo/lg.png';
import { useNavigate } from 'react-router-dom';

export const Header=()=>{
    const Navigate=useNavigate();

    const handleSignIn=()=>{
        Navigate('/signin');


    }


    return(
      <div className='header-pos'>
        <div className="header">
            <div className="logo">
                <img src={logo}/>
            </div>
            <div className="section">
                <a href='/'>HOME</a>
                <a href='/content'>CONTENT</a>
                <a href='/contact'>CONTACT US</a>
                <a href='/about'>ABOUT US</a>
            </div>
            <div className="viewAi"><button onClick={handleSignIn}>Sign In</button></div>
        </div>
        </div>
    )
}
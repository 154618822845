import React from "react";
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { Main } from "./pages/main/main.js";
import { PredModel } from "./pages/other model/predModel.js";
import { SignIn } from "./pages/auth/sign.js";
import {SignUp} from './pages/auth/signup.js';


export const App=()=>{
    

    return(
    
        <Router>
        <div className="App">
            <Routes>
                <Route exact path="/" element={<Main/>} />
                <Route path="/predmodel" element={<PredModel/>} />
                <Route path="/signin" element={<SignIn/>}/>
                <Route path="/signup" element={<SignUp/>}/>
                
            </Routes>
        </div>
    </Router>
      
       
    );

};
import '../../css/main.css';
import { Header } from './header.js';
import {Body} from './body.js';
import {InfoSec} from './infosec.js'
import { Footer } from './footer.js';

export const Main=()=>{

    return(
        <div className='main'>
            <Header/>
            <Body/>
            <InfoSec/>
            <Footer/>

            <div className='unres'>
                <h4>Page Unresponsive on this device use other ! </h4>
            </div>

        </div>
    )

}
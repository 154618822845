import '../../css/main.css';
import {useNavigate} from 'react-router-dom';

export const InfoSec=()=>{
    const Navigate=useNavigate();

    const navigateTo=()=>{
        Navigate('/predmodel')

    };

    return(
        <div className='infosec'>
            <div className='model'>
                <h2>we have build interactive Ai</h2>
                <div className='line-other'></div>
                <h4>Best model you should try</h4>
                <button>Try viewAi</button>
            </div>
          {/* <div className='line'></div> */}
            <div className='other-model'>
                <h3>other models you can try</h3>
                <div className='line-other'></div>
                <div className='other-model-sec'>
                    <div className='om-1'>
                        <p>for predictive</p>
                        <button onClick={navigateTo}>try predAi</button>
                    </div>
                    <div className='om-1'>
                        <p>for predictive</p>
                        <button onClick={navigateTo}>try predAi</button>
                    </div>
                    <div className='om-1'>
                        <p>for predictive</p>
                        <button onClick={navigateTo}>try predAi</button>
                    </div>



                </div>

            </div>
            
        </div>
    )
}
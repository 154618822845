import '../../css/sign.css';
import Image from '../../photo/lg.png';
import GGL from '../../photo/ggl.png';
import FBB from '../../photo/fbb.jfif';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const SignIn = () => {
    const navigate = useNavigate();
    const [input, setInput] = useState({});
    const [message, setMessage] = useState('');

    const handleInput = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                "https://superb-cheesecake-62c90c.netlify.app/api/signin",
                {
                    email: input.email,
                    pass: input.pass
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            if (response.status === 200) {
                setMessage(response.data.message);
                setTimeout(() => {
                    navigate('/');
                }, 2000); // Navigate after 2 seconds
            } else {
                // In case of unexpected status codes in the 2xx range
                setMessage(response.data.message);
            }
        } catch (err) {
            // Handle errors for status codes outside 2xx range
            if (err.response) {
                // The request was made and the server responded with a status code
                // that falls outside the range of 2xx
                setMessage(err.response.data.message);
            } else {
                // Something else happened while setting up the request
                console.error(err);
                setMessage("An unexpected error occurred.");
            }
        }
    }

    return (
        <div className='sign-body'>
            <div className='sign-page'>
                <div className='logo'>
                    <img src={Image} alt='logo' />
                </div>
                <p className='msg'>{message}</p>
                <div className='auth'>
                    <div className='u-name'>
                        <label>Email: </label>
                        <input type='text' placeholder='Enter email' name='email' value={input.email || ''} onChange={handleInput} />
                    </div>
                    <div className='pass'>
                        <label>Password: </label>
                        <input type='password' placeholder='Enter password' name='pass' value={input.pass || ''} onChange={handleInput} />
                    </div>
                    <div className='forgot-signup'>
                        <a href='/forgot-password'>Forgot password</a>
                        <a href='/signup'>Sign up</a>
                    </div>
                    <div className='login-btn'>
                        <button onClick={handleSubmit}>Login</button>
                    </div>
                </div>
                <div className='other-m'>
                    <div id="login_google">
                        <p>Login With Google</p>
                        <img src={GGL} alt="Google" />
                    </div>
                    <div id="login_facebook">
                        <p>Login With Facebook</p>
                        <img src={FBB} alt="Facebook" />
                    </div>
                </div>
            </div>
        </div>
    )
}

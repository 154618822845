
import '../../css/.pred.css';
import {useState} from 'react';
import axios from 'axios';

export const PredModel=()=>{
    const [file,setFile]=useState(null)

    const handleSubmit=async()=>{
        const formData=new FormData();
        formData.append('file',file);
        try {
             const response = await axios.post('http://localhost:8000/pred', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            console.log('File uploaded successfully', response.data);
          } catch (error) {
            console.error('Error uploading file', error);
          }

    }

    return(
        <div>
     
            <div className="predModel">
            <h3>PredModel</h3>
                <div className='model-text'>
                <h4>this is the predictive ml model that predict the outcome of your given data</h4>
                <p>for more info <a>visit</a></p>
                </div>
                
                <div className='upload-file'>
                <p>choose file having .csv .xlsx or .json file</p>
                <div className='file-sub'>
                <input type="file"/>
                    <button onClick={handleSubmit}  className='submit-btn'>submit</button>
                </div>
               
            
                </div>

               
              

            </div>
        </div>

    )
}
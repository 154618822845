import '../../css/main.css';

export const Footer=()=>{
    return(
        <div className='footer-main'>
        <div className='footer'>
            <div className='f1'>
                <ul>
                    <li><a>about us</a></li>
                    <li><a>contact us</a></li>
                    <li><a>linkdn</a></li>
                </ul>

            </div>
            <div className='f2'>
                <ul>
                    <li><a>research</a></li>
                    <li><a>Ai models</a></li>
                    <li><a>career</a></li>
                </ul>


            </div>
            <div className='f3'>
            <ul>
                    <li><a>viewAi</a></li>
                    <li><a>versions</a></li>
                    <li><a>update</a></li>
                </ul>
            </div>
            
        </div>
        <div className='f-webname'>
            <h4><a>viewAi.in</a></h4>
        </div>
        </div>
    )
}